@font-face {
  font-family: "MontserratAlternates";
  src: url(../assets/fonts/MontserratAlternates/MontserratAlternates-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  font-family: "MontserratAlternates";
  width: 100%;
}
[lang="en"] .our-project .union-group-3 {
  position: absolute;
  bottom: 0%;
  right: 0%;
}
[lang="en"] .service-title::before {
  content: "";
  background-image: url("../../../public/media/assets/services/Union2.svg");
  position: absolute;
  top: 20px;
  right: 20%;
  width: 25px;
  height: 25px;
}